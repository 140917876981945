<template lang="pug">
  v-app.split-bg
    v-menu(v-if="user" bottom='' left='' offset-y='' origin='top right' transition='scale-transition')
      template(v-slot:activator='{ attrs, on }')
        v-btn.ml-2( class="selectAccount" min-width='0' text='' v-bind='attrs' style='padding: 0px;' v-on='on')
          h2
            strong(style='color: white;') CUENTA:
              strong
                | {{ ' ' + user.selectAccount.toString().split('/')[0].toUpperCase() + ' ' }}
          v-icon(color='white')
            | mdi-format-list-checks
      v-list(:tile='false' nav='' style="padding: 20px; font-size: 25px; cursor: pointer;")
        div
          app-bar-item(v-for='(n, i) in user.accounts' :key='`item-${i}`')
            v-list-item-title(style='padding-right: 0px;' @click='changueAccount(n.name)' v-text="n.name.toString().split('/')[0].toUpperCase()")
    v-btn(color="red" class="closeSesion" @click="handleLogut")
      | Cerrar Sesión
    div(class="divSus")
      v-container#config-price(fluid='' tag='section' style="padding-top: 7%; margin-bottom:5rem;")
        row(style="padding: 50px;")
          v-row
            v-col(cols='12' md='6' style="padding-top: 50px;").text-center
              h1(style="color: #fff;") VERIFIQUE EL TIPO DE CUENTA
              br
              h2(style="color: #fff;") Suscripción CANCELADA o tiempo de suscripción excedido por favor contáctanos para reanudar tu suscripción.
              h3(style="color: rgb(197 181 181);") Si la suscripción ha sido actualizada, cierra sesión e ingresa nuevamente gracias.
          v-row
            v-col(cols='12' md='6').text-center
              v-btn.ml-2(min-width='0' color='red' text='' style='' @click="openLink('https://www.facebook.com/dental.prime.391/')")
                v-icon(color="white") mdi-facebook
              v-btn.ml-2(min-width='0' text=''  style="" @click="openLink('https://www.instagram.com/dentalprimeapp/')")
                v-icon(color="white") mdi-instagram
              v-btn.ml-2(min-width='0' text='' style="" @click="openLink('https://wa.me/+593984530998')")
                v-icon(color="white") mdi-whatsapp
          v-row
            v-col(cols="12" md='6' style='margin:0;').text-center
              img(:src='imgLogo' alt='DentalPrime Logo' width='120' height='120')
</template>
<script>
  import image from '@/assets/img/logo.png'
  // import firebase from 'firebase'
  import firebase from 'firebase/app'
  import 'firebase/auth'
  export default {
    data: () => ({
      imgLogo: image,
    }),
    computed: {
      user () {
        return this.$store.state.users.user
      },
    },
    created () {
      this.user = this.$store.state.users.user
    },
    methods: {
      handleLogut () {
        this.dialog = false
        firebase.auth().signOut().then(() => {
          localStorage.clear()
          this.$store.dispatch('users/cleanStates')
          this.$store.dispatch('patients/cleanData')
          this.$store.dispatch('general/clearData')
          this.$store.dispatch('financial/clearData')
          this.$router.push('/')
        })
      },
      changueAccount (name) {
        this.user.selectAccount = name
        this.$store.dispatch('users/setAccountUser', this.user).then(res => {
          if (res) {
            this.$router.push('/dashboard/pages/odontogram')
          }
        })
      },
    },
  }
</script>
<style lang="css">
  *{
    font-family: 'ceraProMedium';
  }
  .v-btn.v-size--default{
    font-size: 1.2rem;
  }
  .divSus {
    background-image: url("~@/assets/img/suscription-end.jpeg");
    /* background-image: url("https://drmaysammirzaie.ir/wp-content/uploads/2018/02/Orthodontics-wallpaper.jpg"); */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
    height: 100%;
  }
  .closeSesion {
    height: 38px;
    font-weight: bold;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .selectAccount {
    position: absolute;
    top: 10px;
    right: 200px;
  }
</style>
